:root {
    --mobile-menu-height: 70px;
}

.Modal {
    height: calc(100% - var(--mobile-menu-height));
}

.Dashboard {
    background-color: rgba(94, 110, 130, 0.15);
    border-radius: 8px;
    padding-block: 20px;

    .LayoutLine {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: space-between;
        margin-block-end: 40px;
        gap: 20px;
    }

    .Select {
        width: 160px;
        padding-inline: 10px;
        padding-block: 2px;
        font-size: var(--normal-text-size);
        font-weight: 500;
        color: rgba(0, 0, 0, 0.6);
    }

    .GraphCard {
        border-radius: 8px;
        background-color: white;
        padding: 16px 20px;
        width: 285px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        box-shadow: 0 0 15px 10px rgba(65, 69, 88, 0.1);

        .Header {
            display: flex;
            gap: 5px;

            span {
                font-size: var(--normal-text-size);
                font-weight: 700;
                color: rgb(52, 64, 80);
            }

            div {
                color: red;
            }
        }

        .Content {
            display: flex;
            height: 100%;
            gap: 20px;

            .Details {
                display: flex;
                flex-direction: column;
                gap: 10px;

                span {
                    font-size: 35px;
                    font-weight: 400;
                    color: rgb(94, 110, 130);
                }

                div {
                    color: rgb(44, 123, 229);
                    border-radius: 8px;
                    width: fit-content;
                    padding-inline: 8px;
                    background-color: rgba(136, 132, 216, 0.4);
                }
            }

            .Graph {
                width: 100%;
                height: 100%;

                // this is used to remove the line pointing to the X-Axis on hover, couldn't find a built-in option to remove it
                .recharts-tooltip-cursor {
                    display: none;
                    visibility: hidden;
                }

                .custom-tooltip {
                    border: 1px solid rgb(216, 226, 239);
                    border-radius: 5px;
                    background-color: rgb(249, 250, 253);
                    padding-inline: 5px;
                    font-size: var(--normal-text-size);
                    box-shadow: rgba(0, 0, 0, 0.2) 1px 2px 10px;
                }
            }

            .PieGraph {
                width: 100%;
                overflow: visible;

                .recharts-legend-item {
                    margin-block-end: 5px;
                }

                .recharts-legend-wrapper {
                    inset-inline-start: 0 !important;
                    inset-block-start: 0 !important;
                }

                svg {
                    overflow: visible;
                }
            }

            .BarGraph {
                width: 100%;
                height: 100%;

                .recharts-rectangle {
                    height: 5px;
                    border-radius: 8px;
                    overflow: hidden;
                }

                .custom-bar {
                    fill: #8884d8;
                    stroke: #8884d8;
                    stroke-width: 2;
                    rx: 5;
                    ry: 5;
                }

            }
        }
    }

    .Line {
        width: 100%;
        height: 323px;
        max-width: 500px;

        .Header {
            display: flex;
            justify-content: space-between;
        }
    }

    .ProjectList,
    .ProductList {
        width: 100%;
        max-width: 600px;
        height: fit-content;
        background-color: white;
        border-radius: 8px;
        overflow: hidden;
        box-shadow: 0 0 15px 10px rgba(65, 69, 88, 0.1);

        .Header {
            width: 100%;
            height: 10%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-block: 16px;
            padding-inline: 20px;
            background-color: #F9FAFD;
        }

        .Title {
            width: 50%;
        }

        .Column1,
        .Column2 {
            width: 20%;
            text-align: right;
            align-items: end;
            justify-content: end;
        }

        .List {
            .Item {
                display: flex;
                justify-content: space-between;
                padding-block: 10px;
                padding-inline: 20px;
                border-block-end: 1px solid #f0f0f0;
                box-sizing: border-box;

                .Details {
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    .Icon {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-weight: 700;
                        min-width: 30px;
                        min-height: 30px;
                        background-color: #EDF2F9;
                        border-radius: 50px;
                    }

                    .Name {
                        font-size: var(--normal-text-size);
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.6);
                    }

                    .Percentage {
                        width: 100%;
                        font-size: 10px;
                        color: #2C7BE5;
                        font-weight: 600;
                        border-radius: 8px;
                        width: fit-content;
                        padding-inline: 6px;
                        padding-block: 2px;
                        background-color: #EDF2F9;
                    }

                    .Text {
                        display: flex;
                        flex-direction: column;
                        gap: 5px;
                    }

                    .Type {
                        color: rgba(0, 0, 0, 0.3);
                    }

                    img {
                        width: 60px;
                        height: 40px;
                    }
                }

                .Data {
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    .Bar {
                        background-color: #f0f0f0;
                        height: 5px;
                        width: 80px;
                        border-radius: 8px;

                        .Filling {
                            height: 100%;
                            border-radius: 8px;
                            background-color: cornflowerblue;
                        }
                    }
                }
            }
        }

        .Extra {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #F9FAFD;
            height: fit-content;

            .ShowAll {
                padding-block: 10px;
                font-size: var(--normal-text-size);
                font-weight: 400;
                color: #82CAF7;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .ProductList {
        .List .Item {
            justify-content: space-between;
            width: 100%;
        }

        .Extra {
            justify-content: space-between;
            padding: 10px;
        }

        .ViewAll {
            border: 1px solid white;
            border-radius: 5px;
            padding: 5px;
            box-shadow: 0 0 1px 1px rgba(43, 45, 80, 0.1);
            background-color: white;
            color: rgba(0, 0, 0, 0.6);
        }
    }

    .Storage {
        // removing half the gap and the inline paddings
        width: calc(70% - 10px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 16px 20px;
        border-radius: 8px;
        background-color: white;
        box-shadow: 0 0 15px 10px rgba(65, 69, 88, 0.1);
        gap: 20px;

        .Header {
            span {
                font-weight: 700;
            }
        }

        .Bars {
            display: flex;
            width: 100%;
            background-color: #EDF2F9;
            height: 10px;
            border-radius: 8px;
            overflow: hidden;

            .Filling {
                height: 100%;
                background-color: cornflowerblue;
            }
        }

        .Legend {
            display: flex;
            gap: 20px;

            .FillingInfo {
                display: flex;
                align-items: center;
                gap: 5px;

                .Color {
                    height: 10px;
                    width: 10px;
                    border-radius: 10px;
                }

                .Name {
                    font-size: var(--normal-text-size);
                    color: rgb(157, 169, 187);
                }
            }
        }
    }

    .StorageAd {
        position: relative;
        // removing half the gap and the inline paddings
        width: calc(30% - 10px);
        display: flex;
        flex-direction: column;
        padding: 16px 20px;
        border-radius: 8px;
        background-color: white;
        box-shadow: 0 0 15px 10px rgba(65, 69, 88, 0.1);
        gap: 20px;
        overflow: hidden;

        img {
            position: absolute;
            object-fit: cover;
            width: 100%;
            height: 100%;
            // undo paddings positioning of container
            inset-block-start: 0;
            inset-inline-start: 0;
        }

        .Title {
            color: #F58140;
            font-size: 18px;
            font-weight: 600;
        }

        .Text {
            color: rgb(157, 169, 187);
        }

        .Link {
            color: #F58140;
        }
    }
}

.radial-progress {
    width: 100px;
    height: 100px;
    position: relative;

    svg {
        width: 100%;
        height: 100%;
    }

    &-circle {
        fill: transparent;
        stroke: #3A8BF7;
        stroke-width: 8;
        stroke-linecap: round;
        transform: rotate(-90deg);
        transform-origin: center;
        transition: stroke-dashoffset 0.1s linear;

        &-background {
            fill: transparent;
            stroke: #EDF2F9;
            stroke-width: 8;
            stroke-linecap: round;
            transform: rotate(-90deg);
            transform-origin: center;
            stroke-dasharray: 251;
        }
    }

    &-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 24px;
        font-weight: bold;
        color: #333;
    }
}

.OwnerDashboard {
    width: 100%;
    display: flex;
    gap: 20px;

    .Menu {
        max-width: 80px;
        min-width: 80px;
        border-radius: 10px;
        overflow: hidden;
        height: fit-content;
        transition: all 0.2s ease;
        border: 1px solid #EDF2F9;
    }

    .MobileMenu {
        height: var(--mobile-menu-height);
        position: fixed;
        inset-block-end: 0;
        inset-inline-start: 0;
        padding-block: 10px;
        z-index: 10000;
        border-block-start: 1px solid #EDF2F9;
        box-shadow: 0px 0px 10px -2px rgba(0, 0, 0, 0.75);
        -webkit-box-shadow: 0px 0px 10px -2px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 0px 0px 10px -2px rgba(0, 0, 0, 0.75);
        width: 100%;
        display: flex;
        background-color: white;

        .Item {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 100%;
            gap: 5px;

            &[data-is-chosen=true] {
                color: rgb(22, 119, 255);

                svg {
                    width: 20px;
                    height: 20px;
                    fill: rgb(22, 119, 255);
                }
            }

            svg {
                width: 20px;
                height: 20px;
            }
        }
    }

    .Collapsed {
        max-width: 210px;
        min-width: 210px;
    }

    .FirstPage {
        max-width: calc(100% - 230px);
        position: relative;
        flex-grow: 1;

        @media (max-width: 740px) {
            max-width: 100%;
        }

        h1 {
            margin-block-end: 20px;
            font-size: 32px;
            font-weight: 700;
            line-height: normal;

            @media (max-width: 740px) {
                margin-block-end: 10px;
            }
        }

        h2 {
            font-size: 25px;
            font-weight: 700;
            line-height: normal;
            margin-block-end: 15px;
        }

        h3 {
            font-size: 18px;
            font-weight: 700;
        }

        .Reservation {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-block-end: 20px;
        }

        .Tags {
            display: flex;
            flex-wrap: wrap;
            gap: 5px;
            margin-block-end: 20px;

            .Tag,
            .ant-select-selector {
                cursor: pointer;
                border: 1px solid #EDF2F9;
                border-radius: 20px;
                padding: 5px 10px;
                background-color: white;

                @media (min-width: 741px) {
                    &:hover {
                        background-color: #D7DBDD;
                        border-color: black;
                    }
                }
            }
        }

        .GreyArea {
            background-color: #EDF2F9;
            border-radius: 15px;
            width: 100%;
            height: 200px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin-block-end: 40px;
            gap: 20px;

            svg {
                width: 30px;
                height: 30px;
            }

            p {
                max-width: 200px;
                font-size: 14px;
                text-align: center;
            }
        }

        .Cards {
            display: flex;
            gap: 20px;
            margin-block-end: 40px;

            @media (max-width: 740px) {
                flex-direction: column;
                gap: 0;
                margin-block-end: 15px;
            }

            .Card {
                display: flex;
                gap: 10px;
                padding: 15px;
                border-radius: 10px;
                max-width: 420px;
                flex-wrap: nowrap;
                align-items: flex-start;

                @media (max-width: 740px) {
                    margin-block: 5px;
                }

                svg {
                    width: 35px;
                    height: 35px;
                }

                .Text {
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                    width: 100%;
                }
            }
        }

        .Conseils {
            h2 {
                margin-block-end: 20px;
            }

            .Cards {
                display: flex;
                flex-wrap: wrap;
                gap: 10px;
                margin-block-end: 24px;

                .Hotel {
                    width: 215px;
                    height: 200px;

                    @media (max-width: 740px) {
                        width: 280px;
                        height: 250px;
                    }
                }
            }
        }

        .ShadowFix {
            .ant-table-container::after {
                box-shadow: inset 10px 0 8px -8px rgba(5, 5, 5, 0.06);
            }
        }
    }

    .Calendars {
        .Calendar {
            .ant-picker-calendar-date-value {
                background-color: var(--background-color);
            }

            .ant-picker-calendar-date-content {
                background-color: var(--background-color);
                overflow: hidden;
            }
        }
    }

    .MobileMenuPage {
        display: flex;
        flex-direction: column;
        margin-block-end: 25px;

        .MenuSection {
            display: flex;
            flex-direction: column;
            gap: 10px;
            border-block-end: 1px solid gray;
            padding-block-end: 25px;
            margin-block-end: 25px;

            h2 {
                color: gray;
                font-size: var(--normal-text-size);
                margin-block-end: 5px;
            }

            .Link {
                display: flex;
                justify-content: space-between;

                span {
                    font-size: 20px;
                    display: flex;
                    gap: 10px;
                }
            }
        }

        .Buttons {
            display: flex;
            flex-direction: column;
            gap: 15px;

            .Button {
                height: 40px;

                span {
                    font-size: var(--normal-text-size);
                }
            }
        }
    }

    .SelectOffer {
        display: flex;
        flex-direction: column;
    }

    .Accept {
        &:hover {
            color: green;
        }
    }

    .Delete {
        &:hover {
            color: red;
        }
    }
}

.Messages {
    .ant-switch-handle::before {
        background-color: var(--color-of-button);
    }

    .ant-table-row {
        cursor: pointer;
    }
}

.MessagesList {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
    overflow-y: scroll;
    height: 430px;
    margin-block-end: 10px;

    @media (max-width: 740px) {
        height: calc(100% - 34px);
    }

    >* {
        line-height: normal;
    }

    .Day {
        width: 100%;
        text-align: center;
        font-size: 12px;
        font-weight: 700;
    }

    .Message {
        position: relative;
    }

    .MessageWrapper {
        display: flex;
        gap: 15px;
        background-color: #f1f1f1;
        padding: 10px;
        width: 85%;
        border-radius: 5px;
        animation: MessagePop 0.5s forwards;
        -webkit-animation: MessagePop 0.5s forwards;

        @keyframes MessagePop {
            from {
                translate: -100%;
            }

            to {
                translate: 0;
            }
        }

        .Content {
            display: flex;
            flex-direction: column;
            flex: 1;
            gap: 5px;

            .Info {
                display: flex;
                justify-content: space-between;
            }

            .Name {
                font-size: var(--normal-text-size);
                font-weight: 600;

                span {
                    margin-inline-start: 5px;
                    font-weight: 400;
                    color: gray;
                    font-size: 12px;
                }
            }

            .Options {
                display: flex;
                gap: 5px;
            }

            .Text {
                line-height: 19px;
                max-width: 80%;
            }
        }
    }
}

.MessagingPage {
    display: flex;
    width: 100%;
    margin-block-end: 50px;

    @media (max-width: 740px) {
        flex-wrap: wrap;
        gap: 10px;
    }

    .Section {
        width: 20%;
        padding: 10px;
        border: 1px solid #ccc;
        margin-inline-end: 10px;
        border-radius: 10px;

        @media (max-width: 740px) {
            padding: 5px;
            margin-inline-end: 0;
        }

        .Reminder {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 5px;
            font-weight: 700;
            padding-block-end: 10px;
            margin-block-end: 10px;
            border-block-end: 1px solid #ccc;
        }

        li {
            border: 1px solid #ccc;
            border-radius: 10px;
            width: 100%;
            margin-block-end: 10px;
            display: flex;
            gap: 5px;
            line-height: 16px;

            &:last-child {
                margin-block-end: 0;
            }

            img {
                width: 45%;
                height: 90px;
                border-radius: 10px;
            }
        }

        &-Details {
            width: 60%;

            @media (max-width: 740px) {
                width: 50%;
                flex: 1;

                &-Name {
                    display: flex;
                    justify-content: space-between;
                    margin-block-end: 2px;
                }

                &-Message {
                    color: gray;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin-block-end: 2px;
                }

                &-Reservation {
                    color: gray;
                }
            }

            &-Offer {
                margin-block-end: 3px;
            }

            p {
                width: fit-content;
                font-weight: 600;
            }

            p,
            span {
                font-size: 12px;
            }
        }
    }

    .People {
        width: 25%;
        max-height: 607px;
        overflow: auto;

        @media (max-width: 740px) {
            width: 100%;
            max-height: calc(100vh - 280px);
            order: 1;
            padding: 0;
            border: none;
            overflow: visible;

            li {
                padding: 5px;
                padding-block: 10px;
                border-radius: 0;
                border-inline: none;
                border-block-start: none;
                margin-block-end: 0;
                display: flex;
                align-items: center;

                img {
                    height: 50px;
                    width: 50px;
                    border-radius: 25px;
                }
            }
        }

        &-Scrollable {
            overflow-y: scroll;
            padding-inline-end: 0;
        }
    }

    .Messages {
        width: 100%;
        flex: 1;

        @media (max-width: 740px) {
            order: 3;
        }

        Input {
            border-radius: 5px;
        }
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    li {
        cursor: pointer;
    }

    li:hover {
        background-color: #efefef;
    }

    .anticon {
        cursor: pointer;

        &:hover {
            color: blue;
        }

        &:focus {
            opacity: 0.5;
        }
    }

    .Details {
        padding: 0;
        margin-inline-end: 0;

        @media (max-width: 740px) {
            width: calc(50% - 10px);
            order: 2;
        }

        &-Rectangle {
            padding: 10px;
            border-block-end: 1px solid #ccc;

            &:last-child {
                border-block-end: none;
            }
        }

        &-Note {
            font-size: 11px;
            color: #dcc;

            svg {
                width: 11px;
                height: 11px;
            }
        }

        .SelectedDetails {
            display: flex;
            gap: 5px;
            margin-block-end: 5px;

            .Name {
                display: flex;
                justify-content: space-between;
                font-weight: 600;

                svg {
                    width: 20px;
                    height: 20px;
                }
            }

            img {
                width: 70px;
                height: 60px;
            }
        }

        &-Content {
            flex: 1;

            &-Offer {
                font-size: 14px;
                font-weight: 400;
            }

            .Stars {
                display: flex;
                gap: 2px;
                font-size: 12px;
            }
        }

        .SelectedDescription {
            .Description {
                &-Text {
                    font-weight: 600;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    -webkit-line-clamp: 3;
                }
            }
        }

        .SelectedCriteria {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .Criteria {
                display: flex;
                gap: 5px;
                align-items: center;

                span {
                    padding: 5px;
                    border-radius: 20px;
                    background-color: #F1F1F1;
                }

                &-Data {
                    line-height: 18px;

                    p {
                        font-size: 14px;

                        &:last-child {
                            font-weight: 600;
                        }
                    }
                }
            }
        }

        h3 {
            color: #ccc;
        }

        a {
            font-size: 14px;
            text-decoration: underline;
            color: #2C7BE5;
        }
    }
}

.Preview {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .General {
        * {
            line-height: normal;
        }
    }

    .Card {
        .CustomCarousel {
            width: 100%;
        }
    }

    .PreviewInfos {
        display: flex;
        flex-direction: column;
        gap: 10px;

        label {
            font-weight: 600;
        }
    }

    .PreviewMessage {
        display: flex;
        flex-direction: column;
        gap: 25px;
    }

    .PreviewLabel label {
        height: 100%;
        font-size: var(--normal-text-size);
    }
}

.ValidationCard {
    display: flex;
    flex-direction: column;
    width: fit-content;
    padding: 15px;
    gap: 5px;
    border: 1px solid #ccc;
    border-radius: 20px;
    margin-block-end: 20px;
}

.FirstPage .ProfileForm {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

