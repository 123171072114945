.Banner {
    height: 550px;
    position: relative;
    margin-block-end: var(--space-between-elements);
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 740px) {
        width: calc(100% + var(--page-inline-padding) * 2);
        height: 450px;
        margin-inline-start: calc(-1 * var(--page-inline-padding));
    }

    img {
        position: absolute;
        object-fit: cover;
        height: 550px;
        z-index: -1;

        @media (max-width: 740px) {
            height: 450px;
        }
    }
}

.LabelLogo {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    direction: ltr;
    margin-block-end: 50px;
    gap: 20px;
}