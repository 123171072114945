.Notifications {
    display: flex;
    flex-direction: column;
    position: fixed;
    inset-block-start: 100px;
    z-index: 5000;
    gap: 5px;
}

.Notification {
    min-height: 50px;
    padding-inline: 10px;
    opacity: 0;
    transition: opacity 0.3s ease-out;
}

.Show {
    opacity: 1;
}

.Visible {
    inset-inline-end: 30px;
}

.Hidden {
    inset-inline-end: -100%;
}