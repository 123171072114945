/* bullet points style */


.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet, 
.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet  {
    background-color:#F5B72F; 
  }
  

  .swiper-pagination-bullet-active {
    background-color:#F5B72F; 
  }
  