.Cars {
    margin-block-end: 148px;

    &-Title {
        font-size: 30px;
        font-weight: 600;
        margin-block-end: 30px;
    }

    &-Cards {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 50px;
        row-gap: 20px;
        justify-content: center;

        @media (max-width: 940px) {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            row-gap: 20px;
            justify-content: center;
        }
    }
}

.Car {
    display: flex;
    width: 100%;
    border-radius: 8px;
    height: 100%;
    background-color: burlywood;

    @media (max-width: 480px) {
        flex-direction: column;
    }

    &-Title {
        font-size: 25px;
        font-weight: 600;
        margin-block-end: 10px;
    }

    &-Image {
        align-self: center;
        padding-inline: 10px;
        width: 40%;

        @media (max-width: 480px) {
            align-self: flex-start;
            padding-inline: 0;
            padding-block: 10px;
            width: 70%;
        }

        img {
            width: 100%;
            position: static;
            height: 100%;
            object-fit: cover;
        }
    }

    &-Details {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 22px;
        width: 60%;
        color: #4D4D4D;

        @media (max-width: 480px) {
            width: unset;
        }
    }

    &-Description {
        font-size: var(--normal-text-size);
        margin-block-end: 10px;
    }

    &-Search {
        color: yellow;
        font-size: 22px;
        font-weight: 600;
        text-decoration: none;
    }
}