.OffersWrapper {
    margin: auto;
    margin-block-end: 100px;
}

.OfferWrapper {
    position: relative;
    height: 280px;
    max-height: 280px;

    img {
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 280px;
        border-radius: 4px;
        z-index: 1;
    }

    .ImageGradientFullColor {
        position: absolute;
        top: 0;
        inset-inline-start: 0;
        z-index: 2;
        min-height: 100%;
        min-width: 30%;
    }

    .ImageGradientOverlay {
        position: absolute;
        top: 0;
        inset-inline-start: 30%;
        z-index: 2;
        min-height: 100%;
        min-width: 70%;
    }

    .Offer {
        z-index: 3;
    }

    .ImageGradientLeft::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, rgba(255, 165, 0, 1) 0%, rgba(255, 165, 0, 1) 35%, rgba(255, 165, 0, 0) 50%);
        mix-blend-mode: multiply;
        z-index: 1;
    }

    .ImageGradientRight::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to left, rgba(255, 165, 0, 1) 0%, rgba(255, 165, 0, 1) 35%, rgba(255, 165, 0, 0) 50%);
        mix-blend-mode: multiply;
        z-index: 1;
    }
}

.Offer {
    position: absolute;
    inset-block-start: 20%;
    inset-inline-start: 50px;
    max-width: 339px;
    width: 100%;

    .Title {
        color: white;
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: -0.24px;
        margin-block-end: 8px;
    }

    .Text {
        color: white;
        font-size: var(--normal-text-size);
        font-weight: 400;
        line-height: 16px;
        margin-block-end: 24px;
    }

    .Button {
        display: flex;
        padding: 10px 24px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        color: #0A0A0A;
        font-size: var(--normal-text-size);
        font-weight: 700;
        line-height: 16px;
        border: none;
        border-radius: 6px;
        background: white;
    }
}

.SwiperSlideImages {
    max-height: 600px;
    overflow: hidden;
}