/* Pagination style */
.custom-pagination .ant-pagination-item {
  background-color: #f2622a !important;
  border-color: #f2622a !important;
  color: #fff !important;
}

.custom-pagination .ant-pagination-item-active {
  background-color: #ff944d !important;
  border-color: #ff944d !important;
}
