@mixin InputsStyling {
  &-Input {
    position: relative;
    width: 100%;
    max-width: calc(100% / 2);

    @media (max-width: 740px) {
      max-width: 100%;
    }

    input,
    select {
      border: none;
      height: 30px;
      max-height: 30px;
      border-radius: 5px;
      width: 100%;
      padding-inline-start: 10px;
      font-size: var(--normal-text-size);

      @media (max-width: 820px) {
        border: 1px solid grey;
        background-color: white;
      }
    }

    .ant-input-number-input-wrap {
      position: absolute;
      inset-block-start: 3px;
    }

    &-Select {
      height: 100%;
      width: 100%;

      &[data-is-reversed=true] {
        background-position: left 0.75rem center;
      }

      &[data-is-selected=true] {
        color: black;
      }
    }

    select {
      padding-block: 2px;
      height: 34px;
      min-height: 34px;
      color: rgb(0, 0, 0, 0.6);
    }

    img {
      position: absolute;
      top: 7px;
      right: 0;
    }

    &>div {
      min-width: 100%;
      height: 38px;
    }
  }

  &-Buttons {
    display: flex;
    width: 100%;
    padding-block: 10px;
    gap: 10px;

    &-Submit {
      flex-grow: 1;
      background-color: var(--baytup-orange);
      font-weight: 600;
      color: white;
      border-style: none;
      border-radius: 5px;

      &:hover {
        opacity: 0.8;
      }
    }

    .show-read-more {
      display: inline;
    }

    &-Options {
      margin: auto;
      width: fit-content;
      display: flex;
      gap: 10px;
      padding-block: 5px;
      padding-inline: 10px;
      align-items: center;
      border-style: none;
      border-radius: 5px;
      background-color: transparent;

      p {
        font-size: var(--normal-text-size);
        font-weight: 600;
        white-space: nowrap;
      }
    }
  }

  &-Inputs {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 10px;
    margin-block-end: 5px;

    @media (max-width: 740px) {
      flex-direction: column;
      align-items: center;
    }

    .ant-input-number-wrapper {
      height: 100%;
    }

    &-Drawer {
      display: flex;
    }
  }

  &-Drawer {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .Search-Input {
      max-width: 100%;
    }

    .ant-input-number {
      height: 40px;
    }
  }
}

.Search {
  --search-margin-inline: 16px;
  max-width: 70%;
  width: 100%;

  &-Buttons {
    &-Submit {
      padding: 4px 40px;
      width: 100%;
    }
  }

  &-Tabs {
    display: flex;
    height: 50px;
    gap: 10px;
    background-color: white;
    border-radius: 8px 8px 0 0;
    border-bottom: 1px solid lightgray;
  }

  &-Tab {
    display: flex;
    align-items: center;
    gap: 5px;
    padding-inline: 10px;
    cursor: pointer;
    transition: border 0.1s ease-in;

    &[data-is-selected="true"] {
      border-bottom: 2px solid black;
      font-weight: 600;
    }
  }

  &-Area {
    position: relative;

    .InputsWrapper {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      column-gap: 10px;
      margin: 10px 0 20px 0;

      .InputWrapper {
        margin-block: auto;
        padding: 0;
      }
    }

    select,
    input {
      border-color: white;
      background-color: #f8f8f8;
    }

    .Search-Input-Location {
      input {
        width: 100%;
      }

      svg {
        inset-inline-end: 10px;
        inset-block-start: 10px;
      }
    }

    .FirstFilters {
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .Tags {
      flex-grow: 1;
    }
  }

  &-Form {
    background-color: rgb(255, 255, 255, 0.8);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-block: 30px;
    padding-inline: 60px;

    &-Wrapper {
      width: 100%;
      opacity: 0;
      animation: appear 0.5s forwards;
      -webkit-animation: appear 0.5s forwards;

      @keyframes appear {
        from {
          opacity: 0;
        }

        to {
          opacity: 1;
        }
      }
    }

    @media (max-width: 740px) {
      padding-inline: 24px;
    }
  }

  @include InputsStyling;
}

.Search,
.SearchContent {
  &-Input {
    &-Location {
      position: relative;

      .Suggestions,
      .Highlighted {
        font-size: var(--normal-text-size);
        color: grey;
      }

      &-Content {
        width: 100%;
        background-color: white;
        border-radius: 8px;
        position: absolute;
        inset-inline-start: 0;
        inset-block-start: 38px;
        overflow: hidden;
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, .25);
        z-index: 10;

        .Suggestions {
          background-color: white;
          margin: 0;
          padding: 0;
          display: flex;
          flex-direction: column;
          max-height: 145px;
          overflow-y: scroll;
          border: 1px solid white;

          ul,
          li {
            list-style-type: none;
            height: fit-content;
          }

          li {
            padding-inline-start: 5px;
            padding-block: 5px;
            border-block-end: 1px solid #F5F5F5;

            &:hover {
              background-color: #F5F5F5;
              cursor: pointer;
            }
          }
        }

        .Highlighted {
          text-decoration: underline;
          font-weight: 600;
          color: black;
        }
      }
    }

    &-Date {
      width: 100%;
      height: 100%;

      input {
        min-height: 30px;
        inset-block-start: 2px;
      }
    }
  }

  .Card:first-child {
    margin-top: 0;
  }
}

.DateRangeWrapper {
  position: absolute;
  z-index: 10;
  inset-inline-start: -100%;

  .rdrMonthName {
    text-align: center;
  }
}

.Card {
  @include InputsStyling;
}

.mb-10 {
  margin-bottom: 10px;
}

.DateRangeWrapper {
  position: absolute;
  z-index: 10;
  inset-inline-start: -100%;

  .rdrMonthName {
    text-align: center;
  }
}

.Card {
  display: flex;
  margin-block: 24px;
  border: 1px solid #E0E0E0;
  border-radius: 10px;
  overflow: hidden;
  --image-dimensions-width: 350px;
  --image-dimensions-height: 265px;

  &[data-is-map=true] {
    .Hotel-Benefits {
      * {
        text-align: center;
      }


      .Hotel-Baths {
        border-inline: 1px solid black;
      }
    }
  }

  @media (max-width: 740px) {
    flex-direction: column;
  }

  .Image {
    max-width: var(--image-dimensions-width);
    max-height: var(--image-dimensions-height);
    user-select: none;
    -webkit-user-select: none;

    .swiper-slide {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .swiper,
    .swiper-wrapper,
    .swiper-slide {
      height: 100%;
    }
  }

  img {
    width: 100%;
    height: 100%;
    max-width: var(--image-dimensions-width);
    max-height: var(--image-dimensions-height);
  }

  .show-read-more {
    display: inline;
  }

  .hide-read-more {
    display: none;
  }

  .SeeMore {
    cursor: pointer;
    color: var(--baytup-orange);
    font-size: large;
  }

  .SeeLess {
    cursor: pointer;
    color: red;
    font-size: large;
  }

  .Details {
    width: 65%;
    flex-grow: 1;
    padding: 16px 20px;
    display: flex;
    flex-direction: column;

    @media (max-width: 740px) {
      width: 100%;
    }

    .General {
      display: flex;
      justify-content: space-between;

      .Info {
        display: flex;
        flex-direction: column;
      }

      .Name {
        line-height: normal;
        font-size: 24px;
        font-weight: 600;
        margin-block-end: 5px;
      }

      .Description {
        margin-block: 10px;
      }
    }

    .Tabs {
      .ant-tabs {
        &-nav {
          height: 25px;
          margin-block-end: 8px;
        }

        &-tab {
          margin-inline-end: 15px;
        }
      }

      .Item {
        font-size: 10px;
        width: fit-content;
      }

      .SpecificationTag {
        color: #191205;
        border-radius: 9px;
      }
    }

    .Price,
    .MapPrice {
      white-space: nowrap;
      padding: 14px 12px;
      height: fit-content;
      background-color: #E0E0E0;
      border-radius: 5px;
      color: var(--baytup-orange);
      font-size: 20px;
      font-weight: 600;
      justify-self: flex-end;
      width: fit-content;
    }

    .MapPrice {
      background: none;
      border: none;
      padding-inline: 0;
      padding-block: 0;
      font-size: 22px;
      font-weight: 600;
    }

    .Location {
      color: #7B5C18;
    }

    .LocationIcon {
      margin-right: 4px;
    }
  }

  .Tabs {
    .ant-tabs-nav {
      height: 25px;
      margin-block-end: 8px;
    }

    .Item {
      font-size: 10px;
      width: fit-content;
    }

    .SpecificationTag {
      color: #191205;
      border-radius: 9px;
    }
  }

  .BookNow {
    margin: 10px;
    margin-block-start: 10px;
    margin-block-end: 0;
    width: 100%;

    button {
      background-color: #F5B72F;
      margin-right: auto;
      margin-left: auto;
    }

    button,
    .ant-btn-primary {
      &:hover {
        color: white !important;
        opacity: 0.8;
      }
    }
  }

  .CustomCarousel {
    width: 70%;
    margin: 0;
    padding-inline: 50px;

    .swiper-button-next {
      inset-inline-end: 0px;
    }
  }
}

.gutter-row {
  margin-bottom: 10px;
}

.Homepage-Location {
  .ant-popover-inner-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .LocationContent,
  .Inputs {
    display: flex;
    flex-direction: column;
    padding: 10px;
    gap: 5px;

    .Antd-Slider {
      margin-block-end: 10px !important;
    }

    .Places {
      display: flex;
      flex-direction: column;
      gap: 5px;
      max-height: 100px;
      overflow-y: scroll;
    }

    .Place {
      min-height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #E0E0E0;
      border-radius: 5px;
      overflow: hidden;

      &:hover {
        opacity: 0.7;
      }
    }

    .SliderProgress {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        color: var(--baytup-orange);
      }
    }

    .Buttons {
      display: flex;
      justify-content: space-between;
      padding: 10px;
      box-shadow: 0px -2px 14px 0px rgba(0, 0, 0, 0.13);
      -webkit-box-shadow: 0px -2px 14px 0px rgba(0, 0, 0, 0.13);
      -moz-box-shadow: 0px -2px 14px 0px rgba(0, 0, 0, 0.13);
    }
  }

  .Inputs {
    .Place {
      padding-block: 5px;
      padding-inline: 10px;
      gap: 10px;
      width: fit-content;
      background-color: #e6f2fd;

      span {
        border: 1px solid black;
        width: 12px;
        height: 12px;
        border-radius: 10px;
      }
    }
  }

  .LocationContent {
    padding: 0;
  }

  .Choices {
    padding: 10px;
  }
}

.SearchContent {
  margin-block-start: 24px;
}

.HorizontalDivider {
  height: 2px;
  background-color: #191205;
  width: calc(100% - 30px);
  margin-inline: 15px;
  margin-block-end: 1px;
}

.ShowMore {
  max-height: 0;
  overflow: hidden;
  display: none;

  @keyframes fade-in {
    0% {
      max-height: 0;
      margin-block-end: 0;
      display: none;
    }

    100% {
      max-height: 33px;
      margin-block-end: 10px;
      display: flex;
    }
  }

  @keyframes fade-out {
    0% {
      max-height: 33px;
      margin-block-end: 10px;
      display: flex;
    }

    100% {
      max-height: 0;
      margin-block-end: 0;
      display: none;
    }
  }

  &.open {
    display: flex;
    animation: fade-in 0.7s ease-in forwards;
    -webkit-animation: fade-in 0.7s ease-in forwards;
  }

  &.close {
    animation: fade-out 0.7s ease-out forwards;
    -webkit-animation: fade-out 0.7s ease-out forwards;
  }

  &-Button {
    transition: all 0.7s ease-in;

    &.open {
      transform: rotateX(180deg);
    }

    &.close {
      transform: rotateX(0deg);
    }
  }
}

.SearchMapContainer {
  display: flex;
  position: relative;
  width: 40%;
  min-height: 100%;

  @media (max-width: 740px) {
    width: 100%;
  }
}

.SearchContent {
  display: flex;
  gap: 10px;

  @media (max-width: 740px) {
    flex-direction: column-reverse;
  }

  .ResultsWrapper {
    width: 100%;
  }

  &[data-is-map=true] {
    .ResultsWrapper {
      width: 100%;
      max-width: calc(60% - 10px);

      @media (max-width: 740px) {
        max-width: 100%;
      }
    }

    .Hotel {
      min-height: 175px;

      &-Info {
        width: 100%;
      }
    }
  }

  .SearchMap {
    width: 100%;
    max-width: 40%;
    position: relative;

    &-Wrapper {
      max-width: 100%;
      // desktop
      width: calc(0.4 * 1120px);
      height: 700px;
      border-radius: 10px;

      @media (max-width: 740px) {
        height: 450px;
        width: 100%;
      }

      .leaflet-marker-icon {
        background-color: transparent;
        border: none;

        .MapMarkerIcon {
          position: absolute;
          white-space: nowrap;
          color: white;
          background-color: var(--baytup-orange);
          width: fit-content;
          height: fit-content;
          border-radius: 7px;
          padding: 5px;
          top: 0;
          left: calc(-100% - 8px);
        }
      }
    }
  }

  .isAbsoluteTop {
    position: fixed;
    top: 0;
  }

  .isAbsoluteBottom {
    position: absolute;
    bottom: 0;
  }
}

.GridList {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 20px;
  row-gap: 20px;

  .Hotel {
    min-height: 240px;
  }

  @media (max-width: 740px) {
    grid-template-columns: repeat(2, 1fr);

    .Hotel {
      min-height: 180px;
    }
  }

  @media (max-width: 420px) {
    grid-template-columns: repeat(1, 1fr);

    .Hotel {
      min-height: 220px;
    }
  }
}

.Filters-Modal {
  &-Button {
    position: absolute;
    top: 10px;
    inset-inline-end: 5px;
  }
}

.Tags {
  display: flex;
  max-width: none;
  gap: 10px;
  overflow-x: scroll;
  transition: left 3s ease-in-out;
  align-items: center;

  @media (max-width: 740px) {
    flex-wrap: wrap;
    margin-block: 5px;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.Tag {
  min-width: 50px;
  font-size: 14px;
  white-space: nowrap;
  text-align: center;
  background-color: #f8f8f8;
  border-radius: 5px;
  color: grey;
  padding: 10px 16px;
}

.Filters {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  .DisplayFitlers {
    display: flex;
    width: fit-content;
    gap: 5px;

    .ant-select-clear {
      display: none;
    }
  }

  .ant-btn {
    max-width: 75px;
  }

  .ant-btn-default {
    background-color: var(--baytup-orange);
    border-color: white;
    color: white;
    box-shadow: none;

    &:hover {
      color: white;
      border: 1px solid white;
    }
  }

  .ant-btn-primary {
    color: black;
    border-color: black;

    &:hover {
      background-color: var(--color-of-button);
    }
  }
}

.FirstFilters {
  h3 {
    position: relative;
    // border-block-start: 1px solid lightgray;
    width: 110%;
    inset-inline-start: -5%;
    padding-inline-start: 5%;
    padding-block-start: 5px;
    margin-block-start: 5px;
    font-weight: 600;
    font-size: 15px;
  }
}