.Carousel {
    direction: ltr;

    .swiper {
        width: 100%;
        height: 100%;
        position: relative;

        &[data-is-hidden=true] {
            display: none;
            visibility: hidden;
        }

        .swiper-custom-horizontal {
            width: fit-content !important;
            position: absolute;
            height: 20px;
            inset-block-end: 50px !important;
            inset-inline-start: 50px !important;
        }
        
        .swiper-custom-mobile, .swiper-custom-horizontal {
            .swiper-pagination-bullet {
                background-color: #C2C2C2;

                &-active {
                    background-color: white;
                }
            }
        }
    }
}