@tailwind base;
@tailwind components;
@tailwind utilities;
@import "./styles/index.scss";

/******************* CSS TAILWIND DISABLE *******************/
[type='number']:focus {
	outline: none;
	box-shadow: none;
	border-color: none;
}

/******************* CSS TAILWIND DISABLE *******************/

select:not([size]) {
	padding-inline: 1rem;
}

:root {
	--baytup-orange: #F2622A;
	--color-of-button: #f5ba1d;
	--space-between-elements: 60px;
	--page-inline-padding: 40px;
	--footer-height: 190px;
	--header-height: 81px;
	--increased-text-size: 20px;
}

body,
html {
	font-family: "Nunito", sans-serif;
	font-size: var(--normal-text-size);
	width: 100%;
	height: 100%;
	--normal-text-size: 16px;
}

p,
span,
a {
	font-size: var(--normal-text-size);
}

button {
	font-size: var(--normal-text-size) !important;
}

:where(.css-dev-only-do-not-override-nllxry).ant-btn {
	height: fit-content;
}

* {
	font-family: "Nunito", sans-serif;
}

.default-title {
	font-size: 30px;
	font-weight: 600;
	margin-block-end: 30px;
}

@media (max-width: 820px) {
	:root {
		--space-between-elements: 40px;
		--page-inline-padding: 20px;
	}
}

.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
		-webkit-animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

.NotFound {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.loading-page {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	width: 100%;
	gap: 50px;
}

.loading-logo {
	width: 178px;
	height: 53px;
	position: relative;

	img {
		width: 178px;
		height: 53px;
		position: absolute;
		object-fit: cover;
	}
}

.loading-opacity {
	position: relative;
	width: 178px;
	height: 53px;
	opacity: 0.5;
	background-color: white;
	z-index: 10;
	animation: loading 3s linear infinite;
	-webkit-animation: loading 3s linear infinite;
}

@keyframes loading {
	0% {
		width: 178px;
		margin-inline-start: 0;
	}

	100% {
		width: 0;
		margin-inline-start: 178px;
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

button:focus:hover {
	opacity: 0.8;
	box-shadow: 0 0 3px rgb(0, 0, 0, 0.8);
}

button:hover {
	cursor: pointer;
}

.swiper {
	width: 100%;
	height: 100%;
}

.PageContainer {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.Container {
	max-width: 1200px;
	min-height: calc(100vh - (var(--footer-height) + var(--header-height)));
	width: 100%;
	padding-inline: var(--page-inline-padding);
	height: 100%;
	position: relative;
}

.Navbar {
	width: 100%;
	padding-inline: var(--page-inline-padding);
	max-width: 1200px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 80px;
	background-color: white;

	&-ListingButton {
		display: flex;
		gap: 10px;
		align-items: center;
		color: white;
		height: 40px;

		&:hover {
			color: white !important;

		}
	}
}

.Footer {
	width: 100%;
	padding-inline: var(--page-inline-padding);
	display: flex;
	justify-content: center;
	background-color: #191205;
	color: white;
}

.Footer-Content {
	max-width: 1200px;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: white;
}

.Navbar-Logo {
	cursor: pointer;
}

.Navbar-Logo img,
.Footer img {
	cursor: pointer;
	height: 50px;
}

.Navbar-Tabs {
	display: flex;
	align-items: center;
	gap: 20px;
	font-size: var(--normal-text-size);
	font-weight: 600;

	div {
		display: flex;
		gap: 5px;
		align-items: center;
	}

	svg {
		height: 18px;
		width: 18px;
	}
}

.Bell,
.Avatar {
	position: relative;
	height: 20px;
	cursor: pointer;

	.BellCircle {
		position: absolute;
		top: 0;
		left: 10px;
	}

	.ArrowDown {
		position: absolute;
		bottom: -11px;
		left: 9px;
		fill: var(--baytup-orange);
	}
}

.Avatar {
	font-size: var(--normal-text-size);
	font-weight: 600;
	height: 35px;
	width: 35px;
	color: white;
	background-color: #2D2F31;
	border-radius: 40px;
	border-style: none;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	.BellCircle {
		left: 25px;
	}

	&:hover {
		.ArrowDown {
			display: none;
		}
	}
}

.Navbar-SignIn {
	font-size: var(--normal-text-size);
	font-weight: 600;
	height: 39px;
	width: 90px;
	color: white;
	background-color: var(--color-of-button);
	border-radius: 5px;
	border-style: none;
}

.Navbar-Languages {
	position: relative;
}

.Navbar-Language {
	display: flex;
	position: relative;
	border-radius: 100px;
	align-items: center;
	border-style: none;
	padding: 8px;
	font-weight: 700;
	gap: 5px;
}

.Navbar-Language .Active {
	font-size: var(--normal-text-size);
	font-weight: 700;
	color:var(--baytup-orange);
	background-color: transparent;
	border: none;
}
.Navbar-Language:hover {
	background-color: transparent;
	border: none;
}

.Navbar-Languages .Dropdown {
	display: flex;
	flex-direction: column;
	position: absolute;
	inset-block-start: 120%;
	inset-inline-start: -10px;
	width: 80px;
	padding-block: 5px;
	padding-inline: 10px;
	z-index: 10;
	background-color: #f0f0f0;
	border-radius: 8px;
	box-shadow: 0 0 10px 1px grey;
}

.Navbar-Languages .Dropdown div {
	display: flex;
	justify-content: space-between;
	align-items: center;
	direction: ltr !important;
	margin-block: 2px;
	border-radius: 3;
}

.Navbar-Languages .Dropdown div:hover {
	text-decoration: underline;
	cursor: pointer;
}

.Navbar-Languages .Dropdown svg {
	height: 24px;
}

.Footer-Content>div {
	width: 100%;
}

.Footer-Socials {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-block-start: 20px;
	margin-block-end: 20px;
}

.Footer-Socials-Links {
	display: flex;
	gap: 15px;
}

.Footer-Links {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-block-end: 20px;
}

.Footer-Pages {
	display: flex;
	gap: 15px;
}

.Footer-Pages a {
	text-decoration: none;
	color: white;
}

.Footer-Pages a:hover {
	text-decoration: underline;
	color: var(--color-of-button);
}

.Footer-Divider {
	content: "";
	width: 1px;
	min-height: 100%;
	background-color: white;
}

.Footer-Downloads {
	display: flex;
	gap: 10px;
}

.Footer-Downloads img {
	height: 40px;
}

.Footer-Copyright {
	display: flex;
	justify-content: space-between;
	margin-block-end: 20px;
}

.Footer-Copyright button {
	border-style: none;
	color: white;
	background-color: transparent;
	display: flex;
	align-items: center;
	gap: 5px;
}

.Footer-Copyright button:hover {
	cursor: pointer;
}

@media (max-width: 820px) {

	.Footer-Pages,
	.Footer-Downloads {
		flex-direction: column;
		gap: 10px;
	}

	.Footer-Downloads {
		gap: 80px;
	}

	.Footer-Divider {
		display: none;
	}
}

.react-calendar {
	position: absolute;
	max-width: 400px;
	margin: 0 auto;
	left: calc(50% - 170px);
	z-index: 5;
}

.react-calendar__tile--active {
	background-color: #007bff;
	color: #fff;
}

@keyframes fadeInFromNone {
	0% {
		display: none;
		opacity: 0;
	}

	1% {
		display: block;
		opacity: 0;
	}

	100% {
		display: block;
		opacity: 1;
	}
}

@keyframes fadeInFromNone {
	0% {
		display: none;
		opacity: 0;
	}

	1% {
		display: block;
		opacity: 0;
	}

	100% {
		display: block;
		opacity: 1;
	}
}

@keyframes fadeOutToNone {
	0% {
		display: block;
		opacity: 1;
	}

	99% {
		display: block;
		opacity: 0;
	}

	100% {
		display: none;
		opacity: 0;
	}
}

.SlideMenu {
	overflow: hidden;
	position: fixed;
	top: 0;
	left: 0;
	transition: all 0.25s ease-in;
	z-index: 1001;

	&-Extra {
		width: 100vw;
		height: 100vh;
		overflow: hidden;
		background-color: rgb(255, 255, 255, 0.6);
		position: fixed;
		top: 0;
		left: 0;
		z-index: 1000;

		&.open {
			animation: fadeInFromNone 0.25s forwards;
			-webkit-animation: fadeInFromNone 0.25s forwards;
		}

		&.close {
			animation: fadeOutToNone 0.25s forwards;
			-webkit-animation: fadeOutToNone 0.25s forwards;
			z-index: -1;
		}
	}

	.open {
		transform: translate3d(0vw, 0, 0);
		overflow: hidden;
	}

	&.close {
		transform: translate3d(-100vw, 0, 0);
	}
}

.Mobile-Menu {
	width: 50vw;
	height: 100vh;
	overflow: hidden;
	background-color: #ffe600;
	position: fixed;
	top: 0;
	left: 0;
	transition: all 0.25s ease-in;
	z-index: 1000;
}

.Mobile-Menu-Extra {
	width: 50vw;
	height: 100vh;
	overflow: hidden;
	background-color: rgb(255, 255, 255, 0.6);
	position: fixed;
	top: 0;
	left: 50vw;
	transition: all 0.25s ease-in;
	z-index: 1000;
}

.Mobile-Menu.close,
.Mobile-Menu-Extra.close {
	transform: translate3d(-100vw, 0, 0);
}

.Mobile-Menu.open,
.Mobile-Menu-Extra.open {
	transform: translate3d(0vw, 0, 0);
	overflow: hidden;
}

.Mobile-Menu-Button {
	display: block;
	background-color: #007bff;
	color: #fff;
	padding: 10px;
	cursor: pointer;
}

.No-Scroll {
	position: fixed;

	// .PageContainer {
	//   &> {

	//     .Container,
	//     .Navbar,
	//     .Footer {
	//       padding-inline-start: calc(var(--page-inline-padding) + 15px);
	//     }
	//   }
	// }
}

.Select {
	option {
		&[hidden] {
			display: none;
			visibility: hidden;
		}

		&:disabled {
			display: none;
			visibility: hidden;
		}
	}
}

.custom-select {
	--bs-custom-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
	display: block;
	padding: 0.375rem 2.25rem 0.375rem 0.75rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: rgb(0, 0, 0, 0.6);
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background-color: #fff;
	background-image: var(--bs-custom-select-bg-img);
	background-repeat: no-repeat;
	background-position: right 0.75rem center;
	background-size: 16px 12px;
	border: 1px solid 3dee2e6;
	border-radius: 0.375rem;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

	&[data-is-reversed=true] {
		background-position: left 0.75rem center;
	}

	&[data-is-selected=true] {
		color: #212529;
	}

	* {
		color: #212529;
	}
}

@media (prefers-reduced-motion: reduce) {
	.custom-select {
		transition: none;
	}
}

.custom-select:focus {
	border-color: #86b7fe;
	outline: 0;
	box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
	padding-inline-end: 0.75rem;
	background-image: none;
}

.custom-select:disabled {
	background-color: #343a40;
}

.custom-select:-moz-focusring {
	color: transparent;
	text-shadow: 0 0 0 #212529;
}

.SignInGoogle {
	background-color: white;
	border: 1px solid #ccc;
	border-radius: 4px;
	padding: 10px 15px;
	display: flex;
	align-items: center;
	flex-direction: row-reverse;
	cursor: "pointer";

	&[data-is-reverse="true"] {
		flex-direction: row-reverse;
	}

	img {
		margin-inline-end: 5px;
		margin-inline-start: 10px;
	}
}

.CarouselCustom {
	max-width: 600px;
	margin: 0 auto;

	&-item {
		padding: 10px;
		text-align: center;
	}
}

.Popup {
	position: absolute;
	max-width: 700px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: white;
	padding: 20px;
	border: 1px solid #ccc;
}

.Blurred {
	display: fixed;
	position: fixed;
	background-color: rgba(204, 204, 204, 0.4);
	width: 100vw;
	height: 100vh;
	z-index: 2000;
}

.RadioButton {
	display: flex;
	align-items: center;
	gap: 10px;
	background-color: transparent;

	input:focus {
		outline-color: var(--baytup-orange);
		box-shadow: 0 0 0 2px transparent;
	}

	input:checked {
		background-color: var(--baytup-orange);
		outline-color: var(--baytup-orange);
		box-shadow: 0 0 0 2px transparent;
	}
}

.Antd-Slider {
	&:hover {
		.ant-slider-handle {
			&::after {
				box-shadow: 0 0 0 2px var(--baytup-orange);
			}
		}
	}

	.ant-slider {
		&-dot {
			display: none;
		}

		&-mark {
			&-text {
				white-space: nowrap;

				&:first-child {
					inset-inline-start: 5% !important;
				}

				&:last-child {
					inset-inline-start: 90% !important;
				}
			}
		}

		&-handle {
			&::after {
				box-shadow: 0 0 0 2px var(--baytup-orange);
			}

			&:focus,
			&:hover {
				&::after {
					box-shadow: 0 0 0 4px var(--baytup-orange);
				}
			}
		}
	}
}

.StandardInput {
	@apply bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500;
}

.star {
	&-filled {
		width: 18px;
		height: 18px;
		stroke-width: 1px;
		animation: AppearFromNone 0.5s ease-in-out;
		-webkit-animation: AppearFromNone 0.5s ease-in-out;
		fill: var(--color-of-button);
	}

	&-outlined {
		width: 18px;
		height: 18px;
		stroke-width: 1px;
		animation: AppearFromNonen 0.5s ease-in-out;
		-webkit-animation: AppearFromNonen 0.5s ease-in-out;
		fill: none;
	}
}

// two of the same keyframes, not sure what i'm missing but unless i do this i can't use it twice on the same element
@keyframes AppearFromNone {
	0% {
		transform: scaleX(1);
	}

	25% {
		transform: scaleX(0.8);
	}

	75% {
		transform: scaleX(1.2);
	}

	100% {
		transform: scaleX(1);
	}
}

@keyframes AppearFromNonen {
	0% {
		transform: scaleX(1);
	}

	25% {
		transform: scaleX(0.8);
	}

	75% {
		transform: scaleX(1.2);
	}

	100% {
		transform: scaleX(1);
	}
}

.hover-text-blue {
	:hover {
		color: #0958d9;
	}
}

.ant-input-number-handler-wrap {
	z-index: 10;
}

.Table {
	.Name {
		min-width: 250px;
		max-width: 250px;
		display: flex;
		gap: 10px;
	}
}

.custom-label-above-input {
	height: 63px;
	width: 100%;

	.ant-form-item {
		&-label {
			text-align: initial;
		}

		&-row {
			display: flex;
			flex-direction: column;
			margin-bottom: 8px;
			width: 100%;

			.ant-form-item-required {
				width: 100%;
			}
		}
	}
}

.ant-tooltip {
	z-index: 1000;
}

.ImageCapture {
	display: flex;
	gap: 10px;
	flex-direction: column;
	width: 95%;

	.avatar-uploader {
		width: 100px;
	}

	.document-uploader {
		height: fit-content;
	}
}