.Country {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-block-end: 150px;

    @media (max-width: 940px) {
        margin-block-end: 40px;
        flex-direction: column;
    }

    &-Title {
        @extend .default-title
    }

    &-Places {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 50px;
        row-gap: 20px;
        justify-content: center;

        @media (max-width: 480px) {
            grid-template-columns: repeat(2, 1fr);
        }

        a {
            text-decoration: none;
            color: var(--baytup-orange);
            font-size: var(--increased-text-size);
        }
    }

    &[data-is-flipped="true"] {
        flex-direction: row-reverse;

        .Country-Places a {
            color: #f5b72f;
        }

        @media (max-width: 940px) {
            flex-direction: column;
        }
    }

    img {
        width: 289px;
        height: 280px;

        @media (max-width: 940px) {
            margin-top: 20px;
            width: 60%;
            height: 300px;
            max-width: 350px;
        }

        @media (max-width: 480px) {
            width: 80%;
        }
    }

    &-Text {
        display: flex;
        flex-direction: column;
        width: 65%;

        @media (max-width: 940px) {
            align-self: flex-start;
            width: 100%;
        }
    }
}