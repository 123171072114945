.Auth {
  height: 100%;
  width: 100%;
  display: flex;

  @media (max-width: 720px) {
    padding-inline: 0;
    width: 100%;
    max-width: 100%;
  }

  * {
    font-family: Poppins !important;
  }

  .Header {
    position: relative;

    &-Text {
      text-align: center;
      font-size: 32px;
      margin-block: 45px 60px;
    }
  }

  .FormWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .Bubble {
    position: absolute;
    z-index: -1;
    inset-inline-start: calc(50% - (367px / 2));
    inset-block-start: -50px;
    width: 367px;
    height: 252px;
    transform: rotate(17.327deg);
    flex-shrink: 0;
    border-radius: 184px;
    background: #f5ba1e;
    box-shadow: 0px -2px 7px 0px rgba(0, 0, 0, 0.25) inset;

    @media (max-width: 720px) {
      inset-block-start: -30px;
    }
  }

  .Success {
    color: var(--color-of-button);
    text-align: center;
    margin: auto;
    font-size: 25px;
  }

  .Content {
    position: relative;
    max-width: 550px;
    padding: 60px 20px 30px 20px;
    width: 100%;
    border-radius: 30px;
    background: #141311;
    margin-block-end: 30px;

    &[data-is-auth=true] {
      padding-block: 40px;
    }
  }

  .SignUpPage {
    .Header {
      overflow: hidden;
      width: 100%;

      &-Text {
        color: white;
      }
    }

    .Content {
      padding-block-end: 40px;
    }
  }

  .Form {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    &-Error {
      position: absolute;
      color: red;
      inset-block-start: 30px;
      inset-inline-start: 20px;
    }

    .InputWrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      margin-block-end: 32px;

      &:last-child {
        margin-block-end: 24px;
      }

      &-Error {
        position: absolute;
        inset-block-start: 100%;
        color: red;
      }

      svg {
        position: absolute;
        inset-block-start: calc(50%);
        inset-inline-end: 5px;
      }
    }

    .RadioButtons {
      display: flex;
      gap: 20px;
      margin-block: 10px;
    }

    .Additional {
      display: flex;
      justify-content: space-between;
      margin-block-end: 40px;

      .Remember {
        display: flex;
        align-items: center;
        gap: 5px;

        label {
          color: #999999;
        }
      }

      .Checkbox {
        display: flex;
        position: relative;

        .Checkmark {
          display: none;
          position: absolute;
          inset-inline-start: 10px;
          inset-block-start: 5px;
          width: 5px;
          height: 10px;
          border: solid white;
          border-width: 0 3px 3px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }

        input {
          align-self: center;
          border-radius: 4px;
          border: 1px solid #999;
          height: 20px;
          width: 20px;
          background-color: transparent;
        }

        input:checked~.Checkmark {
          display: block;
        }
      }
    }

    label {
      color: #fff5e1;
      font-size: var(--normal-text-size);
      font-weight: 500;
    }

    input:not([type="radio"]),
    select {
      appearance: none;
      border: none;
      outline: none;
      background: #141311;
      color: white;
      padding-block: 8px;
      border-block-end: 2px solid #999;

      &::placeholder {
        color: #999;
        font-size: var(--normal-text-size);
        font-weight: 400;
      }

      option {
        color: white;
        padding-block: 2px;
      }
    }

    .select_box:after {
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 6px solid var(--color-of-button);
      position: absolute;
      top: 40%;
      right: 5px;
      content: "";
      z-index: 98;
    }

    button {
      align-self: center;
      color: white;
      text-align: center;
      font-size: var(--normal-text-size);
      font-weight: 500;
      line-height: 16px;
      border: none;
      border-radius: 48px;
      background: var(--color-of-button);
      width: 100%;
      padding: 16px 40px;
      max-width: 80%;

      @media (max-width: 720px) {
        max-width: unset;
      }
    }

    p {
      color: #999999;
      text-align: center;
      margin-block: 30px;
    }

    .ForgotPassword {
      color: var(--color-of-button);
      align-self: center;
    }
  }

  .QuestionLink {
    color: var(--color-of-button);
  }

  .DividerWrapper {
    display: flex;
    align-items: center;
    margin-block-end: 35px;

    .Divider {
      height: 1px;
      width: 100%;
      background-color: #e0e0e0;
    }

    .Text {
      width: fit-content;
      color: #999999;
      white-space: nowrap;
      margin-inline: 16px;
    }
  }

  .AlternativeLogins {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
  }

  .AdminLoginPage,
  .LoginPage,
  .SignUpPage {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .AdminLoginPage {
    .Content {
      margin: 0;
      padding-block: 32px;
      width: 100%;
      height: fit-content;
      max-width: 300px;
      background-color: #191205;

      @media (max-width: 720px) {
        border-radius: 30px;
        margin-block-end: 0;
      }

      .Title {
        color: white;
        font-size: var(--increased-text-size);
        text-align: center;
        margin-block-end: 32px;
      }

      .InputWrapper svg:first-child {
        max-width: 20px;
        max-height: 20px;
        position: absolute;
        inset-inline-start: 0;
        inset-block-start: 22px;
      }

      input {
        padding-inline-start: 22px;
      }
    }
  }
}

.LoginPopup {
  h3 {
    font-size: 24px;
    margin-block: 15px;
  }

  .Header {
    position: relative;
    width: 100%;
    max-width: 550px;
    color: white;
    background-color: #141311;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;

    h3 {
      font-size: 24px;
      margin-block: 15px;
    }

    span {
      position: absolute;
      right: 20px;
      top: 25px;
    }
  }

  .Content {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-block-end: 0;
    padding-block-start: 10px;

    .Form-Error {
      inset-block-start: 123px;
    }

    h3 {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 15px;
    }
  }

  .Logo {
    width: 170px;
    height: 50px;
  }
}