.Exclusives {
    margin-block-end: 131px;

    &-Title {
        @extend .default-title
    }

    &-Hotels {
        display: grid;
        grid-template-columns: repeat(3, 360px);
        column-gap: auto;
        row-gap: 20px;
        justify-content: space-between;

        @media (max-width: 1180px) {
            grid-template-columns: repeat(2, 1fr);
            column-gap: 20px;
        }

        @media (max-width: 650px) {
            grid-template-columns: repeat(1, 1fr);
        }
    }
}

.Hotel {
    position: relative;
    height: 100%;
    max-height: 300px;
    display: flex;
    overflow: hidden;

    img {
        border-radius: 8px;
        object-fit: cover;
        width: 100%;
        height: 100%;
        align-self: center;
        max-width: 360px;
    }

    &,
    img {
        @media (max-width: 1180px) {
            max-height: 250px;
            max-width: 400px;
        }

        @media (max-width: 740px) {
            max-width: 450px;
        }

        @media (max-width: 650px) {
            max-width: 100%;
            object-fit: cover;
        }
    }

    &-Info {
        position: absolute;
        color: white;
        bottom: 0;
        width: 100%;
        padding-inline-start: 4px;
        padding-block-end: 10px;
        padding-block-start: 15%;
        border-radius: 8px;
        background: linear-gradient(180deg,
                rgba(0, 0, 0, 0) 0%,
                rgba(0, 0, 0, 0.8) 61.98%);
    }

    &-Price {
        display: flex;
        gap: 8px;
        padding-block-end: 3px;
        font-weight: 700;
        font-size: var(--increased-text-size);

        .Number {
            direction: ltr;
        }
    }

    &-Details {
        display: flex;
        justify-content: space-between;
        font-weight: 500;
    }

    &-Location {
        align-self: center;
    }

    &-Benefits {
        display: flex;

        div {
            padding-inline: 5px;
            text-align: right;
        }
    }

    &-Baths,
    &-Beds {
        border-inline-end: 1px solid white;
    }
}