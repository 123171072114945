.CreateSteps {
    margin-block: 20px;

    .Buttons {
        button {
            background-color: var(--color-of-button);
        }
    }

    .CarouselStepWrapper {
        min-height: 450px;
    }
}

.SaveButton {
    position: absolute;
    top: 10px;
    inset-inline-end: 10px;
    margin-block-start: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.SaveProgress {
    position: absolute;
    top: 10px;
    inset-inline-start: 10px;
    margin-block-start: 60px;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
}

.OfferFormWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    .OfferForm {
        min-width: 460px;
        padding: 20px;
        border: 1px solid #D0D3D4;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .Percentage input {
            @apply bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500;

            &[data-is-rtl=false] {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }

            &[data-is-rtl=true] {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }

        &.Address {
            .ant-form-item {
                margin-block-end: 0px !important;
            }
        }
    }

    .DetailsForm {
        min-width: 500px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(auto-fill, 1fr);

        .Search-Input-Location {
            input {
                padding-inline-start: 25px;
                padding-inline-end: 10px;
                border-radius: 8px;
            }

            svg {
                inset-inline-start: 6px;
                inset-block-start: 12px;
            }

            &-Content {
                z-index: 10000;
            }
        }
    }
}

.Antd-Checkbox {
    &:hover {
        .ant-checkbox-inner {
            border-color: var(--color-of-button) !important;
        }

        .ant-checkbox-checked {
            .ant-checkbox-inner {
                background-color: var(--color-of-button) !important;
            }
        }
    }

    .ant-checkbox-inner {
        background-color: transparent;
    }

    .ant-checkbox-checked {
        .ant-checkbox-inner {
            border-color: var(--color-of-button) !important;
        }
    }

    &-Dark {
        @extend .Antd-Checkbox;

        .ant-checkbox-checked {
            .ant-checkbox-inner {
                background-color: black;
                border-color: black !important;
            }
        }
    }
}

.InputWrapper {
    display: flex;
    flex-direction: column;
    padding-block-end: 5px;

    input[type='checkbox'] {
        width: 20px;
        height: 20px;
    }

    textarea {
        min-height: 42px;
        max-height: 500px;
    }
}

.CarouselStep {
    margin-inline: auto;
    margin-block-start: 20px;
    max-width: 80%;

    .StepHeader {
        display: flex;
        flex-direction: column;
        gap: 10px;
        font-size: 30px;
        font-weight: 600;
        margin-block-end: 20px;
        position: relative;
    }

    .Error {
        display: flex;
        flex-direction: column;
        top: 60px;
        font-size: var(--normal-text-size);
        color: red;
        background-color: white;
        border-radius: 10px;
        padding-inline: 10px;
        box-shadow: 0px 0px 26px 0px rgba(255, 0, 0, 0.6) inset;
        -webkit-box-shadow: 0px 0px 26px 0px rgba(255, 0, 0, 0.6) inset;
        -moz-box-shadow: 0px 0px 26px 0px rgba(255, 0, 0, 0.6) inset;
    }

    .dropzone {
        cursor: pointer;

        * {
            pointer-events: none;
        }
    }

    .SelectedImages {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;

        @media (max-width: 740px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media (max-width: 415px) {
            grid-template-columns: 1fr;
        }

        .Image {
            cursor: pointer;
        }

        &[data-is-loading=true] {
            pointer-events: none;
            opacity: 0.6;
        }
    }

    .AccommodationContent {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;

        @media (max-width: 740px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media (max-width: 415px) {
            grid-template-columns: 1fr;
        }
    }

    .AccommodationCard {
        padding: 20px;
        border: 1px solid #D0D3D4;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 10px;
        cursor: pointer;
        transition: transform 0.3s ease;

        .AccommodationIcon {
            width: 70px;
            height: 70px;
            pointer-events: none;
        }

        .AccommodationName {
            font-size: 25px;
            font-weight: 600;
            pointer-events: none;
        }

        &:hover {
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
        }

        &:active {
            transform: scale(0.9);
        }
    }

    .Chosen {
        padding: 18px;
        border: 3px solid var(--baytup-orange);
    }

    .TypeContent {
        display: flex;
        flex-direction: column;
        gap: 30px;

        .TypeCard {
            padding: 21px;
            border: 1px solid #D0D3D4;
            border-radius: 20px;
            display: flex;
            justify-content: space-between;
            gap: 10px;
            cursor: pointer;

            * {
                pointer-events: none;
            }

            .TypeIcon {
                width: 100px;
                height: 100px;
            }

            .TypeName {
                font-size: 25px;
                font-weight: 600;
            }

            .TypeText {
                font-size: var(--normal-text-size);
                font-weight: 400;
                color: #B3B6B7;
            }

            &:hover {
                padding: 20px;
                border-width: 2px;
                border-color: black;
            }

            &:active {
                opacity: 0.6;
            }

            &[data-is-selected=true] {
                padding: 20px;
                border-width: 2px;
                border-color: var(--baytup-orange);
            }
        }
    }

    .DetailsContent {
        display: flex;
        flex-direction: column;
        margin-block-end: 20px;

        .DetailsRow {
            display: flex;
            justify-content: space-between;
            font-size: 20px;
            font-weight: 500;
            align-items: center;
            padding: 20px;
            border-block-end: 2px solid #D0D3D4;

            .Input {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 15px;
                font-size: var(--normal-text-size);
                font-weight: 500;
            }

            &:last-child {
                border-block-end: none;
            }

            button {
                * {
                    pointer-events: none;
                }

                &:disabled {
                    background-color: #D0D3D4;
                }
            }
        }
    }

    label {
        font-weight: 600;
    }
}

.OfferMap {
    width: 600px;
    height: 500px;
    border: 1px solid black;
    border-radius: 10px;
    overflow: hidden;
    position: relative;

    .SwitchButton {
        position: absolute;
        padding: 5px;
        font-size: 12px;
        line-height: 18px;
        border-radius: 3px;
        inset-block-start: 13px;
        inset-inline-end: 5px;
        z-index: 500;
        background-color: white;
        color: black;
    }
}

.Steps {
    li {
        & span span {
            cursor: pointer;
            white-space: nowrap;

            &::first-letter {
                text-transform: capitalize;
            }
        }

        &[data-is-disabled=true] {
            & span span {
                cursor: not-allowed;
            }
        }
    }

    .state {
        &-done {
            color: var(--color-of-button);
        }

        &-border {
            &-done {
                border-color: var(--color-of-button);
            }
        }

        &-unfinished {
            color: grey;
        }

        &-border {
            &-unfinished {
                border-color: grey;
            }
        }

        &-error {
            color: red;
        }

        &-border {
            &-error {
                border-color: red;
            }
        }
    }
}

.SwiperCustom {
    direction: ltr;
    overflow-x: hidden;

    .swiper-wrapper {
        display: inline-flex;
    }

    .swiper-slide {
        position: relative;
        overflow-x: hidden;
        transition: transform 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
    }
}

.scrollable {
    .CarouselStepWrapper {
        overflow-y: scroll;
        scrollbar-width: thin;
        scrollbar-color: #F5B72F var(--baytup-orange);
        border-radius: 8px;

        &::-webkit-scrollbar {
            width: 10px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: var(--baytup-orange);
            border-radius: 8px;
        }

        &::-webkit-scrollbar-track {
            background-color: #F5B72F;
            border-radius: 8px;
        }
    }
}

.CardsSection {
    display: flex;
    flex-direction: column;
    margin-block-end: 10px;
    gap: 10px;

    .Cards {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 15px;
    }

    .Options {
        display: flex;
        flex-wrap: wrap;
        max-width: 400px;
    }

    .AccommodationCard {
        padding: 5px;
        border-radius: 50px;
        text-align: center;

        &[data-is-selected=true] {
            background-color: var(--baytup-orange);
            border-color: var(--baytup-orange);
            color: white;
        }
    }
}

.UploadCarImages {
    user-select: none;
    align-self: stretch;
    justify-self: stretch;

    .ant-upload {
        min-width: 100%;
        min-height: 100%;
    }

    &:hover {
        .custom-upload-overlay {
            opacity: 0.5;
            z-index: 10;
            background-color: grey;
        }

        .custom-upload-delete,
        .custom-upload-preview {
            display: block;
        }
    }

    .custom-upload {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        border-radius: 8px;
    }

    .custom-upload-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0;
        transition: opacity 0.3s;
        pointer-events: none;
    }

    .custom-upload-preview,
    .custom-upload-delete {
        display: none;
        position: absolute;
        color: #fff;
        cursor: pointer;
        left: 30%;
        line-height: normal;
        height: 16px;
        width: 16px;

        svg {
            fill: black;
        }
    }

    .custom-upload-delete {
        left: 53%;
    }

    .custom-upload-preview:hover,
    .custom-upload-delete:hover {
        svg {
            fill: var(--baytup-orange);
        }
    }
}

.custom-upload-delete,
.custom-upload-preview {
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.2);
    width: 23px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 20;
    cursor: pointer;
}

.custom-upload-preview {
    right: 30px;
}