.ant-select {
    input:focus {
        outline: none;
        border: none;
        box-shadow: none;
    }
}

.ant-btn-default {
    background-color: var(--baytup-orange);
    border-color: white;
    color: white;
    box-shadow: none;

    &:hover {
        color: var(--color-of-button) !important;
        border: 1px solid var(--color-of-button) !important;
    }

    span {
        line-height: normal;
    }
}

.ant-btn-primary {
    color: black;
    border-color: black;

    &:hover {
        background-color: var(--baytup-orange) !important;
        border-color: white;
    }

    span {
        line-height: normal;
    }
}